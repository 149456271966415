import { IProject } from "../models";

export const aboutMe = {
  youtubeLink: "https://www.youtube.com/channel/UCV4AXpDSxschk8I0sCl8JXw",
  blogLink: "https://www.explainedsimply.io",
  resumeLink: "https://particle-frame.herokuapp.com/resume",
  githubLink: "https://github.com/pkellz",
  emailLink: "hello@pscott.io",
  musicLink:
    "https://twitter.com/realDevSage/status/1659543598847737857/video/1",
} as const;

export const projects: IProject[] = [
  // {
  //   name: "explainedsimply.io",
  //   link: "https://explainedsimply.io/",
  //   img: "explainedsimply.png",
  //   description: [
  //     `explainedsimply.io is my blog with a mission to make web development simpler through easy-to-understand explanations.`,
  //   ],
  //   architecture: [
  //     `Jekyll blog hosted on a Digital Ocean Droplet running an nginx server. The blog is built and deployed via GitHub Actions.`,
  //   ],
  //   tech: ["Jekyll", "GitHub Actions", "Digital Ocean", "nginx"],
  // },
  // {
  //   name: "everest crypto trading bot",
  //   link: "https://github.com/pkellz/everest",
  //   img: "everest2.png",
  //   description: [
  //     `Everest is a crypto live-trading CLI bot I built with Node.js.

  //       I love finding ways to integrate my interests of crypto and programming together, so one day I found myself yearning to create my own trading bot. After doing some digging to find a tutorial that could help me get started - alas - the best YouTube tutorial I could find was written in Python.`,

  //     `Unfortunately, as a JavaScript/Node.js-buff, it may as well have been written in Greek - and then translated to hieroglyphics. But I wasn't going to let a little language barrier stop me, so I took some time to port the bot over from Python to Node.js.`,
  //   ],
  //   architecture: [
  //     `Node.js command line interface that is hosted on npm. This bot makes trades on the Poloniex exchange with the (eventual) idea being to make it exchange-agnostic.`,
  //   ],
  //   tech: ["Node.js", "Poloniex API", "Command Line"],
  // },
  {
    name: "fancyserial.io",
    link: "https://fancyserial.io/",
    img: "fancyserial.png",
    description: [
      `In the numismatic world, "fancy serial" is a term that describes a banknote that has a rare or otherwise desirable serial number. There are several types of fancy serials like 'solids', 'radars' (palindromes), 'ladders', 'x-in-a-row', etc.`,

      `Plug a serial number into fancyserial.io and it'll tell you if & why it is fancy.`,
    ],
    architecture: [`Next.js application hosted on Vercel.`],
    tech: ["Next.js", "Vercel"],
  },
  {
    name: "Advanced Postings",
    link: "https://advancedpostings.com/",
    img: "advancedpostings.png",
    description: [
      "'Advanced Postings' helps governments across several South Carolina counties collect unpaid property taxes by printing & posting delinquent tax notices on their behalf.",

      "I co-founded this business with one partner.",
    ],
    architecture: [``],
    tech: [""],
  },
  {
    name: "Build React.js From Scratch",
    link: "https://buildreactjs.io/",
    img: "buildreact.png",
    description: [
      `The amount of content surrounding how to use a particular tool / library far outweighs the amount of content about how to actually BUILD your own tools. I want to bridge that gap.`,

      `So I teach developers how to build their own dev tools from scratch by deconstructing existing tools (like React 16) to understand the patterns and principles behind them.`,
    ],
    architecture: [
      `Static website hosted on a Digital Ocean Droplet running an nginx server. The website is version-controlled in Github and gets deployed to the Droplet via GitHub Actions.`,
    ],
    tech: ["HTML5", "CSS3", "Digital Ocean", "nginx", "Github Actions"],
  },

  {
    name: "DevSage YouTube",
    link: "https://www.youtube.com/channel/UCV4AXpDSxschk8I0sCl8JXw",
    img: "devsage-yt.png",
    description: [
      "DevSage is my YouTube channel where I teach web development / programming to my lovely audience of nearly 20,000 people.",
    ],
    architecture: [],
    tech: [],
  },
  // {
  //     name: 'devsage.io',
  //     link: 'https://devsage.io',
  //     img: 'devsage.png',
  //     description: ["The brand website for my YouTube channel - DevSage. I market my online courses here. (Not yet complete)"],
  //     architecture: [
  //         `This was built with Wordpress.`
  //     ],
  //     tech: ['Wordpress']
  // },
];
